export default {
  "Nigeria": '234',
  "Afghanistan": '93',
  "Albania": '355',
  "Algeria": '213',
  "American Samoa": '685',
  "Andorra": '376',
  "Angola": '1264',
  "Anguilla": '244',
  "Antarctica": '672',
  "Argentina": '54',
  "Armenia": '374',
  "Aruba": '297',
  "Ascension": '247',
  "Australia": '61',
  "Austria": '43',
  "Azerbaijan": '994',
  "Bahamas": '1809',
  "Bahrain": '973',
  "Bangladesh": '880',
  "Barbados": '1246',
  "Belarus": '375',
  "Belgium": '32',
  "Belize": '501',
  "Benin": '229',
  "Bermuda": '1441',
  "Bhutan": '975',
  "Bolivia": '591',
  "Bosnia and herzegovina": '387',
  "Botswana": '267',
  "Brazil": '55',
  "Britain": '44',
  "Brunei": '673',
  "Bulgaria": '359',
  "Burkina Faso": '226',
  "Burma": '95',
  "Burundi": '257',
  "Cameroon": '237',
  "Canada": '001',
  "Cape Verde": '238',
  "Chad": '235',
  "Chile": '56',
  "China": '86',
  "Christmas Island": '618',
  "Columbia": '001',
  "Comoros": '269',
  "Congo": '242',
  "Costa Rica": '506',
  "Cote d'Ivoire": '225',
  "Croatia": '385',
  "Cuba": '53',
  "Cyprus": '357',
  "Czech Republic": '420',
  "Denmark": '45',
  "Djibouti": '253',
  "Dominica": '1890',
  "East Timor": '670',
  "Ecuador": '593',
  "Egypt": '20',
  "Eritrea": '291',
  "Estonia": '372',
  "Ethiopia": '251',
  "Faroe Islands": '298',
  "Fiji": '679',
  "Finland": '358',
  "France": '33',
  "French guiana": '240',
  "French polynesia": '689',
  "Gabon": '241',
  "Gambia": '220',
  "Georgia": '995',
  "Germany": '49',
  "Gibraltar": '350',
  "Greece": '30',
  "Greenland": '45',
  "Grenada": '1809',
  "Guadeloupe": '53',
  "Guam": '1671',
  "Guatemala": '502',
  "Guernsey": '502',
  "Guinea": '224',
  "Guinea Bissau": '245',
  "Guyana": '592',
  "Haiti": '509',
  "Holland": '31',
  "Honduras": '504',
  "Hungary": '36',
  "Iceland": '354',
  "India": '91',
  "Indonesia": '62',
  "Iran": '98',
  "Iraq": '964',
  "Ireland": '353',
  "Israel": '972',
  "Italy": '39',
  "Jamaica": '1876',
  "Japan": '81',
  "Jordan": '962',
  "Kampuchea": '855',
  "Kazakhstan": '007',
  "Kenya": '254',
  "Kiribati": '686',
  "Korea": '82',
  "Kuwait": '965',
  "Kyrgyzstan": '331',
  "Laos": '856',
  "Latvia": '371',
  "Lebanon": '961',
  "Lesotho": '266',
  "Liberia": '231',
  "Libya": '218',
  "Liechtenstein": '423',
  "Lithuania": '370',
  "Lucia": '1758',
  "Luxemburg": '352',
  "Madagascar": '261',
  "Malawi": '265',
  "Malaysia": '60',
  "Maldives": '960',
  "Mali": '223',
  "Malta": '356',
  "Martinique": '596',
  "Mauritania": '222',
  "Mauritius": '230',
  "Mayotte": '262',
  "Mexico": '52',
  "Micronesia": '691',
  "Moldova": '373',
  "Monaco": '377',
  "Mongolia": '976',
  "Montserrat": '1664',
  "Morocco": '212',
  "Mozambique": '258',
  "Namibia": '264',
  "Nauru": '674',
  "Nepal": '977',
  "New Caledonia": '687',
  "New Zealand": '64',
  "Nicaragua": '505',
  "Niger": '227',
  "Niue": '683',
  "Norfolk": '6723',
  "North Korea": '850',
  "Norway": '47',
  "Oman": '968',
  "Pakistan": '92',
  "Palau islands": '680',
  "Palestine": '930',
  "Panama": '507',
  "Papua New Guinea": '675',
  "Paraguay": '595',
  "Peru": '51',
  "Philippines": '63',
  "Poland": '48',
  "Portuguese": '80',
  "Puerto Rico": '1787',
  "Qatar": '974',
  "Reunion": '262',
  "Romania": '40',
  "Russia": '7',
  "Rwanda": '250',
  "Saint Kitts and nevis": '1784',
  "Saint Vincent and the grenadines": '1784',
  "Samoa": '684',
  "San marino": '378',
  "Sao Tome and principe": '239',
  "Saudi Arabia": '966',
  "Senegal": '221',
  "Serbia', Montenegro": '381',
  "Seychelles": '248',
  "Sierra Leone": '232',
  "Singapore": '65',
  "Slovakia": '421',
  "Slovenia": '386',
  "Somalia": '252',
  "South Africa": '27',
  "Spain": '34',
  "Sri Lanka": '94',
  "St. helena": '290',
  "Suriname": '597',
  "Swaziland": '268',
  "Sweden": '46',
  "Switzerland": '41',
  "Syria": '963',
  "Tajikistan": '992',
  "Tanzania": '255',
  "Thailand": '66',
  "The Cayman Islands": '1345',
  "The Central African Republic": '236',
  "The Cocos islands": '61',
  "The Cook islands": '682',
  "The Democratic Republic of Congo": '242',
  "The Frank islands": '49',
  "The French Southern territories": '33',
  "The Marshall islands": '692',
  "The Netherlands antilles": '599',
  "The Northern Mariana islands": '1670',
  "The Republic of Dominica": '1809',
  "The Solomon islands": '677',
  "The Turks and Kaiketesi islands": '1649',
  "The United States": '001',
  "The Vatican": '379',
  "The Virgin Islands', American": '1340',
  "The Virgin Islands', the British": '1284',
  "The island of Antigua and barbuda": '1268',
  "Togo": '228',
  "Tokelau": '690',
  "Tonga": '676',
  "Trinidad and Tobago": '1868',
  "Tristan Da khuon ha": '992',
  "Tunisia": '216',
  "Turkey": '90',
  "Turkmenistan": '992',
  "Tuvalu": '688',
  "Uganda": '256',
  "Ukraine": '380',
  "United Arab Emirates', Arabia": '971',
  "Uruguay": '598',
  "Uzbekistan": '233',
  "Vanuatu": '678',
  "Venezuela": '58',
  "Vietnam": '84',
  "Wallis and futuna": '681',
  "Yemen": '967',
  "Zambia": '260',
  "Zimbabwe": '263',
}