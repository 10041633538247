import axios from 'axios';
import { md5 } from '../utils/md5'

const getRequestId = () =>{
  return 'xxxx-xxxx-xxxx-xxxx'.replace(/[x]/g, function (c) {
    var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
}

const getSignature = (requestIdString, requestTimeString, paramsJSON) =>{
  let md5String = `${requestIdString}${requestTimeString}${paramsJSON}`
  return md5(md5String)
}

export function marketWhatsapp(param = {}){
  const { params = {} } = param
  let paramsJSON = JSON.stringify(params)
  let requestTimeString = new Date().getTime()
  let requestIdString = getRequestId()
  let apiUrl = process.env.NODE_ENV === 'production' ? 'https://api.chat.theiabay.store/market/whatsapp' : 'http://chat2.redmou.icu/market/whatsapp'
  let config = {
    "headers": {
      'Content-Type': 'application/json',
      timestamp: requestTimeString,
      requestId: requestIdString,
      signature: getSignature(requestIdString, requestTimeString, paramsJSON),
    }
  }
  return new Promise((resolve, reject) => {
    console.log(config)
    axios['post'](`${apiUrl}`, paramsJSON, config).then(res => {
      resolve(res)
    }).catch(e =>{
      reject(e)
    })
  })
}