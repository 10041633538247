/*home模块接口列表*/
const home = [
  {
    url: 'getPromotion', //获取推广价格
    code: 900002,
  },
  {
    url: 'getPromotionList', //后台推广价格列表
    code: 900001,
  },
  {
    url: 'editPromotion', //后台编辑推广价格
    code: 900003,
  },
  {
    url: 'highestRate', //
    code: 808723,
  }
]

export default home