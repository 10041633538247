
/*axios封装
 *请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios';
import store from '../store';

/**
* 请求失败后的错误统一处理
* @param {Number} status 请求失败的状态码
*/
const errorHandle = (status) => {
  // 状态码判断
  switch (status) {
    // 401: 未登录状态，跳转登录页
    case 401:
      break;
    // 403 token过期
    case 403:
      break;
    // 404请求不存在
    case 404:
      break;
    default:
  }
}

// 创建axios实例
var instance = axios.create({ withCredentials: true, timeout: 1000 * 20 });
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
instance.defaults.headers.post['Accept-Language'] = localStorage.getItem('lang') || 'en'
/**
* 请求拦截器
* 每次请求前，如果存在token则在请求头中携带token
*/
instance.interceptors.request.use(
  config => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
    const token = store.state.token
    token && (config.headers.Authorization = token)
    try {
      const json = config.data.get('json') ? JSON.parse(config.data.get('json')) : {}
      const { token: transToken } = json
      if (token && !transToken) {
        config.data.delete('json')
        json.token = token
        config.data.append('json', JSON.stringify(json))
      }
    } catch (e){
      console.log(e) 
    }
    return config
  },
  error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    const { response } = error
    if (response) {
      errorHandle(response.status)
      return Promise.reject(response)
    } else {
      // 处理断网的情况
      if(error.message.includes('timeout')){
        window.location.reload()
      }
      return Promise.reject(response)
    }
  }
);

export default instance;
